@import url('https://fonts.googleapis.com/css2?family=Manrope:wght@300;400;500;600;700&family=Noto+Sans:wght@400;500;600;700&display=swap');

:root {
	--font-family: 'Manrope', sans-serif;

	--color-bg: #f3f3f3;
	--color-text-primary-dark: #111111;
	--color-border: #c8c8c9;
	--color-primary-blue: #66ceff;
}

* {
	box-sizing: border-box;
}

a {
	color: inherit;
	text-decoration: none;
}

h1,
h2,
h3,
h4,
h5,
h6 {
	margin: 0;
}
