::-moz-selection {
	/* Code for Firefox */
	color: #fff;
	background: var(--color-text-primary-dark);
}

::selection {
	color: #fff;
	background: var(--color-text-primary-dark);
}

::-webkit-scrollbar {
	display: none;
}

body {
	background-color: var(--color-bg);
	margin: 0;
	padding: 0;
	opacity: 0;
}

/********************************************************* 
	Section Paddings 
**********************************************************/

.section__padding-horizontal {
	padding-left: 6.5rem;
	padding-right: 6.5rem;
}

@media screen and (max-width: 1023px) {
	.section__padding-horizontal {
		padding-left: 3rem;
		padding-right: 3rem;
	}
}

@media screen and (max-width: 600px) {
	.section__padding-horizontal {
		padding-left: 1.5rem;
		padding-right: 1.5rem;
	}
}

.section__padding-vertical {
	padding-top: 6rem;
	padding-bottom: 6rem;
}

@media screen and (max-width: 600px) {
	.section__padding-vertical {
		padding-top: 3rem;
		padding-bottom: 3rem;
	}
}

/********************************************************* 
	Section Titles
**********************************************************/

.section__title-container {
	padding: 1.5rem 0 3rem;
}

.section__title-container .section__title {
	font-family: var(--font-family);
	font-size: calc(16px + 0.2vw);
	font-weight: 700;
	line-height: 1.2;
	text-transform: uppercase;
	color: var(--color-text-primary-dark);
}

/********************************************************* 
	Button Styles 
**********************************************************/

.button {
	position: relative;
	display: inline-flex;
	font-family: var(--font-family);
	padding: 0.75em 2em;
	font-size: 16px;
	font-weight: 500;
	line-height: 1.2;
	letter-spacing: 1px;
	text-transform: uppercase;
	border: 1px solid var(--color-text-primary-dark);
	border-radius: 100vw;
	transition: color 0.2s ease-in-out;
	overflow: hidden;
	z-index: 1;
}

@media screen and (max-width: 600px) {
	.button {
		font-size: 14px;
	}
}

.button::after {
	content: '';
	position: absolute;
	bottom: 0;
	left: 0;
	right: 0;
	width: 100%;
	height: 100%;
	max-height: 0;
	background-color: var(--color-text-primary-dark);
	z-index: -1;
	transition: max-height 0.2s ease-in-out;
}

.button:hover {
	color: #fff;
}

.button:hover::after {
	max-height: 100%;
}
