.preloader__container {
	position: fixed;
	top: 0;
	right: 0;
	width: 100%;
	max-width: 100vw;
	height: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	background-color: var(--color-primary-blue);
	z-index: 10;
	transition: max-width 0.45s ease-in-out;
	overflow: hidden;
}

.preloader__container.is-offscreen {
	max-width: 0;
}
