.hero {
	display: flex;
	flex-direction: column;
	justify-content: flex-end;
	align-items: flex-start;
	min-height: 100vh;
}

.hero__container {
	max-width: 1920px;
	width: 100%;
	margin: 0 auto;
}

.hero .hero__title,
.hero .hero__subtitle {
	font-family: var(--font-family);
	color: var(--color-text-primary-dark);
}

.hero .hero__title {
	font-size: calc(36px + 3.5vw);
	font-weight: 600;
	line-height: calc(40px + 3.5vw);
	margin-bottom: 4rem;
}

@media screen and (min-width: 1921px) {
	.hero .hero__title {
		font-size: 100px;
		line-height: 100px;
	}
}

.hero .hero__title span {
	position: relative;
}

.hero .hero__subtitle {
	font-size: calc(16px + 0.25vw);
	font-weight: 500;
	line-height: 1.6;
	max-width: 600px;
}

.hero .hero__title span,
.hero .hero__subtitle {
	opacity: 0;
}
