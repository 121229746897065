.site__navbar {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	max-width: 100vw;
	padding-top: 3rem;
	padding-bottom: 1.5rem;
	z-index: 10;
	transition: padding 0.5s ease-in-out, box-shadow 0.25s ease-in-out;
}

.site__navbar.is-sticky {
	background-color: #f3f3f3;
	padding-top: 1.5rem;
	box-shadow: 0px 5px 25px 0px rgba(0, 0, 0, 0.05);
}

.site__navbar__container {
	max-width: 1920px;
	width: 100%;
	margin: 0 auto;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
}

.site__navbar .site__navbar-logo img {
	height: 60px;
	margin-bottom: -20%;
}

@media screen and (max-width: 600px) {
	.site__navbar .site__navbar-logo img {
		height: 50px;
	}
}
