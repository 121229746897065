.footer {
	position: relative;
	overflow: hidden;
}

.footer::after {
	content: 'Hire Me';
	position: absolute;
	left: 0;
	right: 0;
	bottom: 0;
	width: 100%;
	height: auto;
	font-family: var(--font-family);
	color: var(--color-text-primary-dark);
	font-size: calc(24px + 16vw);
	font-weight: 700;
	line-height: 1;
	text-transform: uppercase;
	text-align: center;
	opacity: 0.05;
}

.footer__container {
	position: relative;
	max-width: 1920px;
	width: 100%;
	margin: 0 auto;
}

.footer .footer__socials {
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
}

.footer .footer__socials .footer__social-link {
	position: relative;
	width: 60px;
	height: 60px;
	display: flex;
	justify-content: center;
	align-items: center;
	border: 1px solid var(--color-text-primary-dark);
	border-radius: 100px;
	margin: 0 10px;
	font-size: 30px;
	color: var(--color-text-primary-dark);
	overflow: hidden;
	z-index: 1;
	transition: color 0.25s ease-in-out;
}

@media screen and (max-width: 767px) {
	.footer .footer__socials .footer__social-link {
		width: 50px;
		height: 50px;
		font-size: 25px;
	}
}

.footer .footer__socials .footer__social-link:hover {
	color: var(--color-bg);
}

.footer .footer__socials .footer__social-link::after {
	content: '';
	position: absolute;
	bottom: 0;
	left: 0;
	right: 0;
	width: 100%;
	height: 100%;
	max-height: 0;
	background-color: var(--color-text-primary-dark);
	z-index: -1;
	transition: max-height 0.2s ease-in-out;
}

.footer .footer__socials .footer__social-link:hover::after {
	max-height: 100%;
}

.footer .footer__title {
	font-family: var(--font-family);
	color: var(--color-text-primary-dark);
	font-size: calc(32px + 1.5vw);
	font-weight: 500;
	line-height: calc(40px + 1.5vw);
	text-align: center;
	padding: 6rem 1rem 3rem;
}

@media screen and (max-width: 767px) {
	.footer .footer__title {
		padding: 3rem 1rem 2rem;
	}
}

.footer .footer__contact-button {
	display: flex;
	justify-content: center;
	align-items: center;
}

.footer .footer__contact-button .button {
	font-size: calc(16px + 0.25vw);
}
