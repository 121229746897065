.projects .projects__container {
	position: relative;
	max-width: 1920px;
	width: 100%;
	margin: 0 auto;
}

/* .projects .projects__container::before {
	content: '';
	position: absolute;
	top: 0;
	left: 0;
	height: 0;
	width: 100%;
	border: 1px solid var(--color-border);
} */

.projects .projects__container .separator {
	height: 0;
	width: 100%;
	border: 1px solid var(--color-border);
}

.projects .projects__container .projects__collection {
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	justify-content: space-between;
}
