.project__card {
	position: relative;
	width: 48.5%;
	display: flex;
	flex-direction: column;
	margin-bottom: 120px;
}

@media screen and (max-width: 767px) {
	.project__card {
		width: 100%;
		margin-bottom: 60px;
	}
}

.project__card .project__card__image-container {
	position: relative;
	width: 100%;
	height: 450px;
	margin-bottom: 25px;
	overflow: hidden;
}

@media screen and (max-width: 767px) {
	.project__card .project__card__image-container {
		height: 250px;
	}
}

.project__card .project__card__image-container img {
	position: absolute;
	width: 100%;
	height: 100%;
	top: 0;
	bottom: 0;
	object-fit: cover;
	object-position: center center;
}

.project__card
	.project__card__image-container
	.project__card_image-container_thumbnail {
	right: 0;
	left: 0;
}

.project__card
	.project__card__image-container
	.project__card_image-container_overlay {
	right: -100%;
	transition: right 1.5s cubic-bezier(0.3, 1, 0.2, 1);
}

.project__card:hover
	.project__card__image-container
	.project__card_image-container_overlay {
	right: 0;
}

.project__card .project__card_details {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
}

.project__card .project__card_details .project__card_details_name,
.project__card .project__card_details .project__card_details_type {
	font-family: var(--font-family);
	font-size: calc(24px + 0.15vw);
	font-weight: 500;
	line-height: 1.2;
	color: var(--color-text-primary-dark);
}

@media screen and (max-width: 998px) {
	.project__card .project__card_details .project__card_details_name,
	.project__card .project__card_details .project__card_details_type {
		font-size: calc(20px + 0.15vw);
	}
}

@media screen and (max-width: 767px) {
	.project__card .project__card_details .project__card_details_name,
	.project__card .project__card_details .project__card_details_type {
		font-size: 18px;
	}
}

.project__card .project__card_details .project__card_details_type {
	opacity: 0.5;
}
